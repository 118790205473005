import React, { Fragment, useState, useEffect, useContext } from "react"
import _ from "lodash"
import Button from "../Button"
import config from '../../../config';

const ENV_CONFIG = config.get();

const CartItem = (
  CheckoutDataProductsById,
  {
    Id,
    NamePlugtrays,
    BotanicalName,
    CommonName,
    PricePlugtrays,
    Images,
    cartQuantity,
    setCartQuantity,
  }
) => {
  // CheckoutDataProductsById comes from calling checkout(Review=true),
  //     ( Which means that it's non-cached, live data directly from dynamo )
  let image = _.get(Images, 0)
  let renderedImage

  if (image) {
    renderedImage = (
      <img
        alt={NamePlugtrays}
        src={`${ENV_CONFIG.productImageCDNPath}${Id}/0-small.jpg`}
      />
    )
  }
  const handleInputChange = e => setCartQuantity(parseInt(e.target.value))

  const checkoutData = _.get(CheckoutDataProductsById, Id) || {}
  const price = checkoutData.PricePlugtrays || PricePlugtrays
  const quantityForCalc = checkoutData.QuantityValidated || cartQuantity
  const stockLevelOk = checkoutData.Status || ""
  const noticeLimitedStock =
    checkoutData.Quantity !== checkoutData.QuantityValidated ? (
      <div className="cell small-12 callout alert small" role="alert">
        <h3 className="header__small-caps">Low stock</h3>
        <p>
          There are only {checkoutData.QuantityValidated} {NamePlugtrays}s in
          stock, and your cart has {checkoutData.Quantity} in it currently.
          We'll remove {checkoutData.Quantity - checkoutData.QuantityValidated}{" "}
          {checkoutData.Quantity - checkoutData.QuantityValidated === 1
            ? "tray"
            : "trays"}{" "}
          from your cart when you check out.
        </p>
      </div>
    ) : (
        undefined
      )

  return (
    <div key={Id} className="grid-x grid-margin-x cart--product-row">
      {noticeLimitedStock}
      <div className="cell small-6 medium-2">{renderedImage}</div>
      <div className="cell small-6 medium-4">
        <p className="product-row--title">
          <span>{BotanicalName}</span>
          <strong>{CommonName}</strong>
        </p>
        <p className="product-row--item-id">Product ID: {Id}</p>
      </div>

      <div className="cell small-4 medium-1 currency">
        <p>${price}</p>
      </div>

      <div className="cell small-4 medium-3">
        <label htmlFor={`quantity-${Id}`} className="show-for-sr">
          Quantity
        </label>
        <div className="input-group plus-minus-input">
          <div className="input-group-button">
            <button
              type="button"
              className="button hollow circle"
              aria-label="Decrease quantity by one"
              onClick={_.partial(setCartQuantity, cartQuantity - 1)}
            >
              <i className="fi-minus" aria-hidden="true"></i>
            </button>
          </div>
          <input
            className={`input-group-field ${
              checkoutData.Quantity !== checkoutData.QuantityValidated
                ? "error"
                : ""
              }`}
            type="number"
            name={`quantity-${Id}`}
            value={cartQuantity}
            onChange={handleInputChange}
          />
          <div className="input-group-button">
            <button
              type="button"
              className="button hollow circle"
              disabled={checkoutData.Quantity > checkoutData.QuantityValidated}
              aria-label="Increase quantity by one"
              onClick={_.partial(setCartQuantity, cartQuantity + 1)}
            >
              <i className="fi-plus" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>

      <div className="cell small-4 medium-2 currency">
        <p>
          <strong>${PricePlugtrays * quantityForCalc}</strong>
        </p>
      </div>
      <div className="cell small-12 product-row--item__delete">
        <Button
          style="transparent icon fi-trash"
          text="Remove item"
          onClickHandler={_.partial(setCartQuantity, 0)}
        />
      </div>
    </div>
  )
}

export default CartItem
