
export const getActiveOrders = `
query getActiveOrders ($Status:String!) {
  GetActiveOrders(Status: $Status) {
    Products {
      Id
      Quantity
      Status
      QuantityValidated
      PricePlugtrays
      NamePlugtrays
    }
    ShippingService {
      shippingService {
        DaysInTransit
        DeliveryTime
        Price
        ServiceCode
      }
      DeliveryInstructions
      IsGift
      GiftMessage
      WaiveWarranty
    }
    Shipping {
      FirstName
      LastName
      Address
      Address2
      City
      State
      Zip
      Phone
      Email
    }
    Billing {
      FirstName
      LastName
      Address
      Address2
      City
      State
      Zip
      Phone
      Email
    }
    Payment {
      Token
    }
    Id
    PaymentAuthorization {
      ResponseCode
    }
    SourceIp
    Status
    Subtotal
    Tax
    TimestampCreated
    Total
    UserId
  }
}
`