import _ from "lodash"
import React, { useState, useEffect } from "react"
import { getOrder } from "../../graphql/cart"
import { graphqlOperation, API } from "aws-amplify"
import { formatCurrency } from "../../utils/cart"

const OrderDetail = ({ OrderId } = {}) => {
  const [Order, SetOrder] = useState({})

  useEffect(() => {
    const f = async () => {
      const result = await API.graphql(
        graphqlOperation(getOrder, { Id: OrderId })
      )
      const order = result.data.GetOrder
      console.log({ order }, "got order!")
      SetOrder(order)
    }
    f()
  }, [])
  return (
    <div className="grid-x grid-margin-x">
      <div className="callout success cell small-12 medium-8 medium-offset-2">
        <h1>Thank you for your order!</h1>
        <p>
          <strong>Order ID: {OrderId}</strong>
        </p>
        <p>
          You will receive an email confirmation shortly. (Check your spam
          folder if you don't see it.)
        </p>
        <p>
          We'll send you another email with a UPS tracking number when your
          order ships.
        </p>
        <p>
          Need more plants? Check out{" "}
          <a href="https://mountainvalleygrowers.com">
            Mountain Valley Growers
          </a>{" "}
          for larger pots!
        </p>
      </div>
    </div>
  )
}

export default OrderDetail
