// import PropTypes from "prop-types"
import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { graphqlOperation, API }  from 'aws-amplify';
import { Link } from "gatsby"
import { getActiveOrders } from '../../graphql/admin';
import { CSVLink, CSVDownload } from "react-csv";
import { formatCurrency } from '../../utils/cart';
import { format } from 'date-fns'

import Loading from '../Loading';

const buildCustomerCSVOrderLine = ({Billing, Shipping}) => {
    // console.log({Billing, Shipping});
    return [
      _.replace(Billing.Phone, /-/g, ''),    // Customer ID
      `${Billing.FirstName} ${Billing.LastName}`,
      Billing.FirstName,
      Billing.Address,
      Billing.Address2,
      Billing.City,
      Billing.State,
      Billing.Zip,
      'United States of America',
      Billing.State === 'CA' ? 'cal' : 'inter',
      Billing.Email,
      Billing.Phone,
      `${Shipping.FirstName} ${Shipping.LastName}`,
      Shipping.Address,
      Shipping.Address2,
      Shipping.City,
      Shipping.State,
      Shipping.Zip,
      'United States of America',
      Shipping.State === 'CA' ? 'cal' : 'inter',
      0, // Ship Via, look into, either 0, 1, 4
      '',  // Credit card number
      '', // Exp date
      `${Billing.FirstName} ${Billing.LastName}`, // Cardholder name
      Billing.Address,
      Billing.Address2,
      Billing.City,
      Billing.State,
      Billing.Zip,
      'United States of America',
    ]
  };

const buildProductCSVOrderLines = (Order) => {
  const {Products, Billing, Shipping, ShippingService, Total, Tax, TimestampCreated} = Order;
  let totalLines = _.size(Products) + 1;
  if (Shipping.State === 'CA') {
    totalLines += 1;
  }
  const orderDate = new Date(TimestampCreated * 1000);
  const firstPartOfLine = [
    _.replace(Billing.Phone, /-/g, ''),    // Customer ID
    `${Billing.FirstName} ${Billing.LastName}`,
    Order.Id,
    format(orderDate, 'M/dd/yyyy'),
    `${Shipping.FirstName} ${Shipping.LastName}`,
    Shipping.Address,
    Shipping.Address2,
    Shipping.City,
    Shipping.State,
    Shipping.Zip,
    'United States of America',
    '',   // Customer P/O
    _.get(ShippingService, 'shippingService.ServiceCode') === '02' ? '2 Day' : 'Ground',
    '',   // Sales Rep ID
    '12000',    // Accounts Receivable Account
    Total,    // A/R Amount
    Shipping.State === 'CA' ? 'cal' : 'inter',    // Sales Tax Id
    '',     // Invoice Note; appears that "waive warranty" belongs here, maybe special UPS instructions
    totalLines,
  ];

  const buildProductLine = (Product, index) => {
    const { NamePlugtrays, QuantityValidated, Id, PricePlugtrays } = Product;
    return [
      ...firstPartOfLine,
      index + 1,
      QuantityValidated,
      `p${Id}`,
      NamePlugtrays,
      '30900',    // GL Account ( 30300 = mvg,  30900 = plugtrays)
      PricePlugtrays,
      1,    // Tax type
      0,    // Weight
      QuantityValidated * PricePlugtrays * -1,
      '',   // Sales tax agency Id
    ];
  };

  let productLines =  _.map(Products, buildProductLine);

  productLines.push([
    ...firstPartOfLine,
    0,  // Weird line number thing
    0,  // Quantity
    '', // Item ID
    'Freight Amount',   // Description
    '31600',    // GL Account ( 31500 = mvg, 31600 = plugtrays)
    0,    // Unit price
    2,   // Tax type ( should be 2, not 26 per keith)
    0,    // Weight
    _.get(ShippingService, 'shippingService.Price') * -1,
    '',   // Sales tax agency id
  ]);

  if (Shipping.State === 'CA') {
    productLines.push([
      ...firstPartOfLine,
      0,  // Weird line number thing
      0,  // Quantity
      '', // Item ID
      'California',   // Description
      '23600',    // GL Account
      0,    // Unit price
      0,   // Tax type
      0,    // Weight
      Tax * -1,
      'calCalif',   // Sales tax agency id
    ]);
  }

  return productLines;
};
 

const AdminActiveOrders = () => {
    const [orders, setOrders] = useState({loading: true, data: []});
    const [checkedOrders, setCheckedOrders] = useState({});
    const [customerCSVData, setCustomerCSVData] = useState([]);
    const [orderCSVData, setOrderCSVData] = useState([]);
    useEffect(() => {
        const f = async () => {
            const result = await API.graphql(graphqlOperation(getActiveOrders, {Status: ''}));
            setOrders({loading: false, data: _.sortBy(result.data.GetActiveOrders, ['TimestampCreated'])});
        };
        f();
    }, []);

    // console.log({orderCSVData, customerCSVData}, 'got CSV data');

    const renderOrder = ({
      Shipping,
      Billing,
      Payment,
      Id,
      PaymentAuthorization,
      Products,
      Status,
      Total,
      TimestampCreated
    }) => {
      const date = new Date( TimestampCreated * 1000);
    
      const buildProduct = ({Quantity, Id, NamePlugtrays, PricePlugtrays}) => {
        return (
          <p key={Id}>({Quantity}) {Id} {NamePlugtrays} @ {formatCurrency(PricePlugtrays)}</p>
        );
      }
      const toggleSelected = (e) => {
        if (e.target.checked) {
          setCheckedOrders(_.set({...checkedOrders}, Id, true));
        } else {
          setCheckedOrders(_.omit(checkedOrders, [Id]));
        }
      }
    
      return (<tr key={Id}>
        <td><input type='checkbox' checked={_.has(checkedOrders, Id)} onChange={toggleSelected}/></td>
        <td>{date.toLocaleString()}</td>
        <td>{Status}</td>
        <td>{Shipping.FirstName} {Shipping.LastName}</td>
        <td>{formatCurrency(Total)}</td>
        <td><Link to={`/app/admin/orders/${Id}`}>{Id}</Link></td>
      </tr>);
    };

    useEffect(() => {
      const selectedOrders = _.filter(orders.data, ({Id}) => _.has(checkedOrders, Id));
      setCustomerCSVData(_.map(selectedOrders, buildCustomerCSVOrderLine));

      const ordersLines = _.map(selectedOrders, buildProductCSVOrderLines);
      let totalLines = []
      for (const lines of ordersLines) {
        totalLines = [...totalLines, ...lines];
      }
      setOrderCSVData(totalLines);      
    }, [checkedOrders, orders]);


    const downloadActions = [
      <h4 key='0'>Download ({_.size(checkedOrders)} orders) Orders</h4>,
      <CSVLink key='1' filename={"peachtree-customer-export.csv"} data={customerCSVData}>peachtree customer import</CSVLink>,
      <CSVLink key='2' filename='peachtree-order-export.csv' data={orderCSVData}>peachtree order import</CSVLink>
    ];

    return (
        <div>
            {orders.loading === true && 
              <Loading />
            }
            {_.size(checkedOrders) > 0 ? downloadActions : undefined}
            <table>
              <thead>
                <tr>
                  <th>Selected</th>
                  <th>Date</th>
                  <th>Status</th>
                  <th>Customer name</th>
                  <th>Total</th>
                  <th>Order ID</th>
                </tr>
              </thead>
              <tbody>
                {_.map(orders.data, renderOrder)}
              </tbody>
            </table>
        </div>
    );
};

AdminActiveOrders.propTypes = {
}

AdminActiveOrders.defaultProps = {
}

export default AdminActiveOrders;
