import React from "react"
import { navigate } from "gatsby"
import { isAdminFromLocalstorage } from '../../utils/auth';


const AdminRoute = ({ component: Component, location, ...rest }) => {



  if (!isAdminFromLocalstorage() && location.pathname !== `/login`) {
    navigate(`/`)
    return null
  }

  return <Component {...rest} />
}

export default AdminRoute