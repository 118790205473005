import React from "react"
import { Router } from "@reach/router"
import Layout from "../components/layout"
import PrivateRoute from "../components/PrivateRoute"
import AdminRoute from "../components/admin/AdminRoute"
import AdminDashboard from "../components/admin/AdminDashboard"
import AdminProducts from "../components/admin/AdminProducts"
import AdminProductDetail from "../components/admin/AdminProductDetail"
import UserProfileHooks from "../components/user/UserProfileHooks"
import AdminTags from "../components/admin/AdminTags"
import Cart from "../components/cart/Cart"
import CartCheckoutBilling from "../components/cart/CartCheckoutBilling"
import CartCheckoutShipping from "../components/cart/CartCheckoutShipping"
import CartCheckoutShippingAddress from "../components/cart/CartCheckoutShippingAddress"
import CartCheckoutPayment from "../components/cart/CartCheckoutPayment"
import CartCheckoutReview from "../components/cart/CartCheckoutReview"
import Logout from "../components/Logout"
import OrderDetail from "../components/admin/OrderDetail"
import AdminOrders from "../components/admin/AdminOrders"
import AdminOrder from "../components/admin/AdminOrder"
import AdminWarrantyStates from "../components/admin/AdminWarrantyStates"
import Search from "../components/products/Search"
import EmailOptOut from "../components/EmailOptOut"
import CartCheckoutBillingPayment from "../components/cart/CartCheckoutBillingPayment"
const App = props => {
  return (
    <Layout {...props} title="App">
      <Router>
        <Search path="/app/search" />
        <EmailOptOut path="/app/opt-out/:email" />
        <Logout path="/app/logout" />
        <PrivateRoute path="/app/profile" component={UserProfileHooks} />

        <AdminRoute path="/app/admin" component={AdminDashboard} />
        <AdminRoute path="/app/admin/products" component={AdminProducts} />
        <AdminRoute
          path="/app/admin/products/:productId"
          component={AdminProductDetail}
        />
        <AdminRoute path="/app/admin/tags" component={AdminTags} />
        <AdminRoute path="/app/admin/active-orders" component={AdminOrders} />
        <AdminRoute path="/app/admin/orders/:Id" component={AdminOrder} />
        <AdminRoute
          path="/app/admin/warranty-states"
          component={AdminWarrantyStates}
        />

        <Cart path="/app/cart" />
        <CartCheckoutShippingAddress path="/app/checkout/shipping-address" />
        <CartCheckoutShipping path="/app/checkout/shipping" />
        <CartCheckoutBillingPayment path="/app/checkout/billing-payment" />
        <CartCheckoutReview path="/app/checkout/review" />
        <OrderDetail path="/app/orders/:OrderId" component={OrderDetail} />
      </Router>
    </Layout>
  )
}

export default App
