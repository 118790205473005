import React from "react"
import { navigate } from "gatsby"
import {getUser} from '../utils/auth';
import { useEffect } from "react";

const PrivateRoute = ({ component: Component, location, ...rest }) => {

  if (!getUser() && location.pathname !== `/login`) {
    // If the user is not logged in, redirect to the login page.
    navigate(`/login`)
    return null
  }

  return <Component {...rest} />
}

export default PrivateRoute