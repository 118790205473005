// import PropTypes from "prop-types"
import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { graphqlOperation, API }  from 'aws-amplify';
import { Link } from "gatsby"
import { getActiveOrders } from '../../graphql/cart';
import AdminActiveOrders from './AdminActiveOrdersTable';

const AdminOrders = () => {
    return (
        <div>
            <h3>Orders</h3>
            <AdminActiveOrders />
        </div>
    );
};

AdminOrders.propTypes = {
}

AdminOrders.defaultProps = {
}

export default AdminOrders;
