// import PropTypes from "prop-types"
import _ from 'lodash';
import React, { useState, useEffect, Fragment } from 'react';
import { graphqlOperation, API }  from 'aws-amplify';
import { Link } from "gatsby"
import Loading from '../Loading';
import config from '../../../config';

const ENV_CONFIG = config.get();

const getProducts = `
query {
  GetProducts {
    products {
      Id
      NamePlugtrays
      InStockPlugtrays
      PricePlugtrays
      TagsPlugtrays
      CommonName
      BotanicalName
      DisplayPlugtrays
    }
  }
}
`;

const saveProductStock = `
mutation SaveProductPrice(
  $Id: String!,
  $InStockPlugtrays: Int!
) {
  SaveProductPlugtraysStock(Id: $Id, InStockPlugtrays: $InStockPlugtrays) {
    Id
    CommonName
    BotanicalName
    NamePlugtrays
    DescriptionPlugtrays
    InStockPlugtrays
    PricePlugtrays
    MVGPath
    Height
    Hardiness
    FlowerColor
    Characteristics
    Uses
    TagsPlugtrays
    AdminImages
  }
}
`;

function validateNumber(evt) {
  var e = evt || window.event;
  var key = e.keyCode || e.which;

  if (!e.shiftKey && !e.altKey && !e.ctrlKey &&
  // numbers   
  key >= 48 && key <= 57 ||
  // Numeric keypad
  key >= 96 && key <= 105 ||
  // Backspace and Tab and Enter
  key == 8 || key == 9 || key == 13 ||
  // Home and End
  key == 35 || key == 36 ||
  // left and right arrows
  key == 37 || key == 39 ||
  // Del and Ins
  key == 46 || key == 45) {
      // input is VALID
  }
  else {
      // input is INVALID
      e.returnValue = false;
      if (e.preventDefault) e.preventDefault();
  }
}



const AdminProducts = () => {
  const [ products, setProducts ] = useState({loading: true, data: undefined});
  const [ stockLevels, setStockLevels ] = useState({});

  useEffect(() => {
    const f = async () => {
      const result = await API.graphql(graphqlOperation(getProducts));
      setProducts({loading: false, data: _.sortBy(result.data.GetProducts.products, ['BotanicalName', 'CommonName'])});
      const stocklevels = {};
      _.map(result.data.GetProducts.products, ({InStockPlugtrays, Id}) => _.set(stocklevels, Id, InStockPlugtrays))
      setStockLevels(stocklevels);
    };
    f();
  }, []);

  const renderProductRow = (product) => {
    const blur = async () => {
      const result = await API.graphql(graphqlOperation(saveProductStock, {Id: product.Id, InStockPlugtrays: Number(_.get(stockLevels, product.Id))}));
      console.log({result}, 'saved product in-stock!');
    };
    const changed = (event) => {
      const newStockValue = {...stockLevels};
      _.set(newStockValue, product.Id, event.target.value);
      setStockLevels(newStockValue);
    };
      return (
      <tr key={product.Id}>
        <td><Link to={`/app/admin/products/${product.Id}`}>{product.Id}</Link></td>
        <td><img alt={product.NamePlugtrays} src={`${ENV_CONFIG.productImageCDNPath}${product.Id}/0-small.jpg`}/></td>
        <td>{product.BotanicalName}</td>
        <td>{product.CommonName}</td>
        <td>{product.DisplayPlugtrays ? 'Show' : 'Hide'}</td>
        <td>
          <input 
            type='text'
            onBlur={blur}
            onKeyDown={validateNumber}
            onChange={changed}
            value={_.get(stockLevels, product.Id)}
          />
        </td>
        <td>{product.PricePlugtrays}</td>
        <td>
          {product.TagsPlugtrays.map((tag, index) => (
            `${tag}${index + 1 < product.TagsPlugtrays.length ? ', ' : ''}`
          ))}
        </td>
      </tr>
    );
  }

  const renderedProducts = !products.loading ? _.map(products.data, renderProductRow) : undefined;

  return (
    <Fragment>
      {products.loading === true ?
        <Loading />
      :
      <div>
        <h3>Products</h3>
        <pre>
        </pre>
        <Link to="/app/admin/tags">Manage product tags</Link>
        <table>
          <thead>
            <tr>
              <td>ID</td>
              <td>Thumbnail image</td>
              <td>Botanical Name</td>
              <td>Common Name</td>
              <td>Show</td>
              <td># in stock</td>
              <td>Price</td>
              <td>Tags</td>
            </tr>
          </thead>
          <tbody>
            {renderedProducts}
          </tbody>
        </table>
      </div>
      }
    </Fragment>
  );
};

AdminProducts.propTypes = {
}

AdminProducts.defaultProps = {
}

export default AdminProducts
