import _ from "lodash"
import React, { useState, useEffect, useContext } from "react"
import { graphqlOperation, API } from "aws-amplify"
import { checkout } from "../../graphql/cart"
import { navigate } from "gatsby"
import CartOrderSummary from "./CartOrderSummary"
import { CartContext } from "../CartContext"
import { formatCurrency, serviceCodeLookup } from "../../utils/cart"
import UserInfoSummary from "./CartUserInfoSummary"
import Button from "../Button"
import config from "../../../config"

const ENV_CONFIG = config.get()

const useCheckoutReview = () => {
  const [review, setReview] = useState({})

  useEffect(() => {
    const f = async () => {
      const result = await API.graphql(
        graphqlOperation(checkout, { Review: true })
      )
      // console.log({result});
      // const result = await API.graphql(graphqlOperation(getCart));
      setReview(_.get(result, "data.Checkout"))
      // console.log({result}, 'got cart!');
    }
    f()
  }, [])

  return review
}

// If these statuses are encountered -> redirect to payment page & display errors from server
const REJECTED_STATUS = [
  "PAYMENT_ERROR", // Should never happen, but .....
  "PAYMENT_REJECTED",
  "DECLINED",
]

const CartCheckoutReview = () => {
  const [SubmitError, SetSubmitError] = useState(undefined) // TODO: deprecate this
  const cart = useCheckoutReview()

  const [submitState, setSubmitState] = useState({ submitting: false });

  const submit = async e => {
    e.preventDefault()
    try {
      setSubmitState({ submitting: true });
      const result = await API.graphql(
        graphqlOperation(checkout, { Review: false })
      )
      setSubmitState({ submitting: false });
      console.log({ result }, 'got create order result');
      const OrderId = _.get(result, "data.Checkout.OrderId")
      const Status = _.get(result, "data.Checkout.Status")
      console.log({ OrderId, Status }, 'got status');
      if (_.includes(REJECTED_STATUS, Status)) {
        navigate("/app/checkout/billing-payment", {
          state: { Errors: _.get(result, "data.Checkout.Errors") },
        })
        return
      } else if (OrderId) {
        navigate(`/app/orders/${OrderId}`)
      }
    } catch (err) {
      setSubmitState({ submitting: false });
      console.error({ err }, 'got error when submitting!');
      const errMsgs = _.map(_.get(err, "errors"), ({ message }) => message)
      SetSubmitError(errMsgs.join(", "))
    }
  }

  const Cart = _.get(cart, "Cart") || {}

  const buildProduct = ({
    Id,
    QuantityValidated,
    Status,
    PricePlugtrays,
    Images,
    BotanicalName,
    CommonName,
    NamePlugtrays,
  }) => {
    const renderedImage = (
      <img
        alt={NamePlugtrays}
        src={`${ENV_CONFIG.productImageCDNPath}${Id}/0-small.jpg`}
      />
    );

    return (
      <div key={Id} className="cell small-12 cart--product-row">
        <div className="grid-x grid-margin-x">
          {QuantityValidated === 0 &&
            <div className="cell small-12 callout alert small" role="alert">
              <h3 className="header__small-caps">Out of stock!</h3>
              <p>
                We're so sorry! {BotanicalName} {CommonName} is out of stock and will be removed from your order.
              </p>
            </div>
          }
          <div className="cell small-6 medium-2">{renderedImage}</div>
          <div className="cell small-6 medium-4">
            <p className="product-row--title">
              <span>{BotanicalName}</span>
              <strong>{CommonName}</strong>
            </p>
            <p className="product-row--item-id">Product ID: {Id}</p>
            {Status !== 'Ok' && <p className="product-row--stock-warning">Limited stock!</p>}
          </div>

          <div className="cell small-4 medium-2 currency">
            <p>{formatCurrency(PricePlugtrays)}</p>
          </div>

          <div className="cell small-4 medium-2 currency">
            <p>{QuantityValidated}</p>
          </div>
          <div className="cell small-4 medium-2 currency">
            {formatCurrency(PricePlugtrays * QuantityValidated)}
          </div>
        </div>
      </div>
    )
  }

  console.log({ cart })

  const shippingInfo = (
    <>
      <p>
        {_.get(Cart, "Shipping.FirstName")} {_.get(Cart, "Shipping.LastName")}
      </p>
      <p>{_.get(Cart, "Shipping.Address")}</p>
      <p>{_.get(Cart, "Shipping.Address2")}</p>
      <p>
        {_.get(Cart, "Shipping.City")}, {_.get(Cart, "Shipping.State")}{" "}
        {_.get(Cart, "Shipping.Zip")}
      </p>
      <p>
        <i className="fi-telephone"></i> {_.get(Cart, "Shipping.Phone")}
      </p>
      <p>
        <i className="fi-mail"></i> {_.get(Cart, "Shipping.Email")}
      </p>
      <p>
        <i className="fi-arrow-right"></i> Shipping{" "}
        {
          serviceCodeLookup[
          _.get(Cart, "ShippingService.shippingService.ServiceCode")
          ]
        }
      </p>
    </>
  )

  const billingInfo = (
    <>
      <p>
        {_.get(Cart, "Billing.FirstName")} {_.get(Cart, "Billing.LastName")}
      </p>
      <p>{_.get(Cart, "Billing.Address")}</p>
      <p>{_.get(Cart, "Billing.Address2")}</p>
      <p>
        {_.get(Cart, "Billing.City")}, {_.get(Cart, "Billing.State")}{" "}
        {_.get(Cart, "Billing.Zip")}
      </p>
      <p>
        <i className="fi-telephone"></i> {_.get(Cart, "Billing.Phone")}
      </p>
      <p>
        <i className="fi-mail"></i> {_.get(Cart, "Billing.Email")}
      </p>
    </>
  )
  const productsRendered = _.map(Cart.Products, buildProduct)

  const productQuantitiesValidated = _.reduce(_.map(Cart.Products, (Id) => Id.QuantityValidated));

  const totalBox = (
    <div className="cell small-12">
      <div className="grid-x">
        <div className="cell small-12">
          <p>
            <strong>
              Subtotal:{" "}
              <span className="currency">{formatCurrency(cart.Subtotal)}</span>
            </strong>
          </p>
          <p>
            Tax: <span className="currency">{formatCurrency(cart.Tax)}</span>
          </p>
          <p>
            Shipping:{" "}
            <span className="currency">
              {formatCurrency(
                _.get(Cart, "ShippingService.shippingService.Price") || 0
              )}
            </span>
          </p>
          <p className="order-total">
            Total:{" "}
            <span className="currency">{formatCurrency(cart.Total)}</span>
          </p>
        </div>
      </div>
    </div>
  )

  const itemsInCart = (
    <>
      <div className="cell small-12">
        <div className="grid-x grid-margin-x show-for-medium product-table--header__faux">
          <div className="cell medium-6">Item details</div>
          <div className="cell small-4 medium-2 currency-header">Price</div>
          <div className="cell small-4 medium-2 table-header__center">
            Quantity
          </div>
          <div className="cell small-4 medium-2 currency-header">Total</div>
        </div>
      </div>
      {productsRendered}
      {totalBox}
      {productQuantitiesValidated === 0 && <p className="callout warning">Your cart is empty.</p>}
    </>
  )

  return (
    <div className="grid-x grid-margin-x">
      <div className="cell small-12 large-8 large-offset-2">
        <h2>Review &amp; Place Order</h2>
        <form onSubmit={submit}>
          <div className="grid-x grid-margin-x">
            <div className="cell small-12">
              <UserInfoSummary
                heading="Shipping information"
                editLink="/app/checkout/shipping-address"
                infoRendered={shippingInfo}
              />
            </div>
            <div className="cell small-12">
              <UserInfoSummary
                heading="Billing information"
                editLink="/app/checkout/billing-payment"
                infoRendered={billingInfo}
              />
            </div>
            <div className="cell small-12">
              <UserInfoSummary
                heading="Order details"
                editLink="/app/cart"
                infoRendered={itemsInCart}
              />
            </div>

            <div className="cell small-12 order-summary--container"></div>
            <div className="cell small-12">
              <Button
                isDisabled={submitState.submitting || productQuantitiesValidated === 0}
                type="submit"
                style={submitState.submitting ? 'primary expanded loading' : "primary expanded"}
                text="Place Order"
              />
              <br />
              {SubmitError}
              <p className="help-text" style={{ textAlign: "center" }}>
                Your card will be charged when you place your order.
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default CartCheckoutReview
