
import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import TextInput from './TextInput';
import { saveEmail } from '../graphql/users';
import { graphqlOperation, API }  from 'aws-amplify';


const EmailOptOut = ({ email }) => {
  const [didOptOut, setDidOptOut] = useState(false);
  const [Email, EmailRendered, SetEmail] = TextInput({
    labelText: "Email address",
    type: "email",
    required: true,
    placeholderText: "you@email.com",
    helpText: "We'll never sell or spam your email.",
  });

  useEffect(() => {
    if (email) {
      SetEmail(email);
    }
  }, [email]);

  const handleSubmitOptOut = async (event) => {
    event.preventDefault();
    const result = await API.graphql(graphqlOperation(saveEmail, {Email, OptOut: true}));
    setDidOptOut(true);
  };
  const handleSubmitOptIn = async (event) => {
    event.preventDefault();
    const result = await API.graphql(graphqlOperation(saveEmail, {Email, OptOut: false}));
    setDidOptOut(false);
  };

  const optOutRendered = <form onSubmit={handleSubmitOptOut}>
    {EmailRendered}
    <input
            className="button"
            type="submit"
            value="Opt Out"
    />
  </form>;

  const optBackInRendered = <div>
    <p>You have been opted out of all future emails.  If this was a mistake, click below to opt into future emails.</p>
    <button onClick={handleSubmitOptIn} type='button' className='button'>Opt-In</button>
  </div>;

  return <div>
    <h1>Opt-Out</h1>
    <form onSubmit={handleSubmitOptOut}>
      {EmailRendered}
      <input
              className="button"
              type="submit"
              value="Opt Out"
      />
    </form>
    { didOptOut ? optBackInRendered : undefined }
  </div>;
};

export default EmailOptOut;