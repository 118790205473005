import _ from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { searchProducts } from '../../utils/public-api';
import TextInput, {validateNone, validateLength, validatePhone } from '../TextInput';

const PARSED_FACETS = ['Uses', 'Characteristics'];
const calculateFacets = (products) => {
  const facets = {
    FlowerColor: new Set(),
    Hardiness: new Set(),
    Height: new Set(),
    Uses: new Set(),
    Characteristics: new Set(),
  };
  _.map(products, (product) => {
    _.map(facets, (value, key) => {
      const thisVal = _.get(product, key);

      if (_.includes(PARSED_FACETS, key)) {
        const vals = _.split(thisVal, ',');
        _.map(vals, (val) => value.add(_.trim(val)));
      } else {
        value.add(thisVal);
      }
    });
  });
  return facets;
};

const Search = () => {
  const [Products, setProducts] = useState({loading: false, data: []});
  const [Query, QueryRendered, SetQuery] = TextInput({labelText: 'Search Query'});
  const [Facets, setFacets] = useState({loading: false, data: {}});

  useEffect(() => {
    const f = async () => {
        setProducts({loading: true, data: []});
        const result = await searchProducts(Query);
        setProducts({loading: false, data: result.Products});
        console.log({result});
    };
    f();
  }, [Query]);

  useEffect(() => {
    const facets = calculateFacets(Products.data);
    // console.log({facets}, 'got facets!');
    setFacets({loading: false, data: facets});
  }, [Products]);

  const renderProduct = (product) => {
    return <div>
      {product.NamePlugtrays}
      {product.PricePlugtrays}
    </div>;
  };

  const renderFacet = (facet, key) => {
    let renderedFacets = [];
    for (const f of facet) {
      renderedFacets.push(<b>{f}</b>);
    }
    return <div>
      <h2>{key}</h2>
      {renderedFacets}
    </div>;
  };

  const renderedAllFacets = _.map(Facets.data, renderFacet);
  const renderedProducts = _.map(Products.data, renderProduct);

  return <div>
      <h2>Product Search</h2>
      {QueryRendered}
      {renderedAllFacets}
      {renderedProducts}
  </div>;
};

export default Search;