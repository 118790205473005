// import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import AdminActiveOrders from './AdminActiveOrdersTable';
import { fetchWarrantyStates } from '../../utils/warranty-states'
import { States } from '../../utils/states';

const AdminDashboard = () => {
  const [warrantyStates, setWarrantyStates] = useState({loading: true, data: []});

  useEffect(() => {
      const f = async () => {
          const result = await fetchWarrantyStates();
          const statesOmitted = _.difference(_.keys(States),result.WarrantyStates);
          setWarrantyStates({loading: false, data: statesOmitted});
      };
      f();
  }, []);

  return (
      <div className="grid-x">
        <div className="cell small-12">
        <h1>Admin Dashboard</h1>
        <p>
          Currently shipping to all states except : {_.join((warrantyStates.data || []).sort(), ', ')}
        </p>
        <p>
          <Link to='/app/admin/active-orders'>Manage orders</Link> | <Link to="/app/admin/products">Manage products</Link> | <Link to="/app/admin/warranty-states">Manage States Under Warranty</Link>
        </p>
        <h2>Latest orders <small>| <Link to='/app/admin/active-orders'>View all orders »</Link></small></h2>
        <AdminActiveOrders />
        </div>
      </div>
    );
  };

AdminDashboard.propTypes = {
}

AdminDashboard.defaultProps = {
}

export default AdminDashboard
