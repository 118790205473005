import _ from "lodash"
import React, { useState, useEffect, useContext } from "react"
import { graphqlOperation, API } from "aws-amplify"
import { saveBillingPayment, checkout } from "../../graphql/cart"

import { navigate } from "gatsby"
import CartOrderSummary from "./CartOrderSummary"
import { CartContext } from "../CartContext"
import { formatCurrency } from "../../utils/cart"
import CartCheckoutPayment from "./CartCheckoutPayment"
import CartCheckoutCreateAccount from "./CartCheckoutCreateAccount"
import CartCheckoutBilling from "./CartCheckoutBilling"
import SEO from "../seo"
import { Link } from "gatsby"
import CartCheckoutOrderSummary from "./CartCheckoutOrderSummary"
import UserInfoSummary from "./CartUserInfoSummary"
import Button from "../Button"
import { serviceCodeLookup } from "../../utils/cart"

import { useCheckoutContext } from "./hooks"

const CartCheckoutBillingPayment = ({ location }) => {
  const CartContextValue = useContext(CartContext)

  const checkoutState = useCheckoutContext({ location })

  const [submitState, setSubmitState] = useState({ submitting: false })

  const [SubmitError, SetSubmitError] = useState() // TODO : deprecate this, remove, put in submitState
  const [
    billingRendered,
    billingPayload,
    validateBilling,
  ] = CartCheckoutBilling(checkoutState)

  const [
    paymentRendered,
    paymentPayload,
    validatePayment,
  ] = CartCheckoutPayment({ location })

  const [
    createAccountRendered,
    createAccountPayload,
    validateCreateAccount,
  ] = CartCheckoutCreateAccount(billingPayload.Email)

  const submit = async e => {
    e.preventDefault()

    const acceptHandler = async resp => {
      // This function gets called after credit card token has been resolved.
      const { CreateAccount, Password } = createAccountPayload
      const CreateAccountPassword = CreateAccount ? Password : undefined
      console.log({ resp, CreateAccountPassword }, "got resp!")
      if (_.get(resp, "messages.resultCode") === "Error") {
        const errMsgs = _.map(
          resp.messages.message,
          ({ code, text }) => `${text} (${code})`
        )
        SetSubmitError(errMsgs.join(", "))
        setSubmitState({ submitting: false })
        console.error({ errMsgs }, "got error!, cannot proceed!")
        return
      }
      const billingPaymentPayload = {
        CreateAccountPassword,
        Token: resp.opaqueData.dataValue,
        ...billingPayload,
      }
      console.log(
        { billingPaymentPayload },
        "generated payment payload to save to acorn!"
      )

      try {
        const result = await API.graphql(
          graphqlOperation(saveBillingPayment, billingPaymentPayload)
        )
        setSubmitState({ submitting: false })
        console.log({ result }, "finished submit!")
        navigate("/app/checkout/review")
      } catch (err) {
        const errMsgs = _.map(_.get(err, "errors"), ({ message }) => message)
        SetSubmitError(errMsgs.join(", "))
        console.error({ err }, "got error!")
        setSubmitState({ submitting: false })
      }
    }

    if (validateBilling() && validatePayment() && validateCreateAccount()) {
      console.log(
        { billingPayload, paymentPayload, createAccountPayload },
        "handling submit"
      )
      const { FirstName, LastName, Zip } = billingPayload
      const { CreditCardNumber, ExpirationDate, SecurityCode } = paymentPayload
      const { month, year } = ExpirationDate
      const paymentData = {
        authData: {
          clientKey:
            "5S2ysQ493FaV3mc43xa2V2MkCTAH763Tw3a2FH7mss4FVyaXm6aRerJEmBCNkS72",
          apiLoginID: "7kaL2RS9rpm",
        },
        cardData: {
          cardNumber: CreditCardNumber,
          month,
          year,
          cardCode: SecurityCode,
          zip: Zip,
          fullName: `${FirstName} ${LastName}`,
        },
      }
      console.log({ paymentData }, "dispatching payment data!")
      setSubmitState({ submitting: true })
      Accept.dispatchData(paymentData, acceptHandler)
    }
  }

  const renderAddress = ({
    FirstName,
    LastName,
    Address,
    Address2,
    City,
    State,
    Zip,
    Phone,
    Email,
  }) => (
      <div>
        <p>
          {FirstName} {LastName}
        </p>
        <p>{Address}</p>
        <p>{Address2 ? Address2 : ""}</p>
        <p>
          {City}, {State} {Zip}
        </p>
        <p>
          <i className="fi-telephone"></i> {Phone}
        </p>
        <p>
          <i className="fi-mail"></i> {Email}
        </p>
        <p>
          <i className="fi-arrow-right"></i> Shipping{" "}
          {
            serviceCodeLookup[
            _.get(
              checkoutState,
              "data.Cart.ShippingService.shippingService.ServiceCode"
            )
            ]
          }
        </p>
      </div>
    )

  const shippingRendered = _.get(checkoutState, "data.Cart.Shipping") ? (
    renderAddress(checkoutState.data.Cart.Shipping)
  ) : (
      <p>Loading.....</p>
    )

  return (
    <div className="grid-x grid-margin-x">
      <div className="cell small-12 medium-8">
        <SEO title="Billing &amp; Payment Information" includeAcceptJS={true} />
        <UserInfoSummary
          heading="Shipping information"
          editLink="/app/checkout/shipping-address"
          infoRendered={shippingRendered}
        />
        <form onSubmit={submit}>
          {billingRendered}
          {paymentRendered}
          {createAccountRendered}
          <div className="grid-x grid-padding-x">
            <div className="cell small-12">
              <Button
                isDisabled={submitState.submitting}
                type="submit"
                style={submitState.submitting ? "primary expanded loading" : "primary expanded"}
                text="Continue to review order"
              />
            </div>
            <div className="cell small-12">{SubmitError}</div>
          </div>
        </form>
      </div>
      <div className="cell small-12 medium-4">
        <CartCheckoutOrderSummary
          ShippingPrice={
            _.get(
              checkoutState,
              "data.Cart.ShippingService.shippingService.Price"
            ) || 0
          }
          Subtotal={_.get(checkoutState, "data.Subtotal") || 0}
          Tax={_.get(checkoutState, "data.Tax") || 0}
          Total={_.get(checkoutState, "data.Total") || 0}
        />
      </div>
    </div>
  )
}

export default CartCheckoutBillingPayment
