import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { graphqlOperation, API }  from 'aws-amplify';
import CheckboxInput from '../CheckboxInput';
import { States } from '../../utils/states';
import { fetchWarrantyStates } from '../../utils/warranty-states'
import { saveWarrantyStates } from '../../graphql/cart';

const AdminWarrantyStates = () => {

  const [warrantyStates, setWarrantyStates] = useState({loading: true, data: []});

  useEffect(() => {
      const f = async () => {
          const result = await fetchWarrantyStates();
          setWarrantyStates({loading: false, data: result.WarrantyStates});
      };
      f();
  }, []);

  const renderedStates = [];

  const statesKeyed = {};

  _.map(States, (Name, ShortName) => {
    const [checked, rendered, setValue] = CheckboxInput({labelText:Name, key: ShortName});
    renderedStates.push(rendered);
    _.set(statesKeyed, ShortName, {checked, rendered, setValue});
  });

  useEffect(() => {
    const f = async () => {
      _.map(statesKeyed, (state, ShortName) => {
        const {checked, rendered, setValue} = state;
        const value = _.indexOf(warrantyStates.data, ShortName) >= 0;
        setValue(value);
      });
    };
    f();
  }, [warrantyStates]);

  const submit = async (e) => {
    e.preventDefault();
    const States = [];
    _.map(statesKeyed, (state, ShortName) => {
        const {checked, rendered, setValue} = state;
        if (checked) {
            States.push(ShortName);
        };
    });
    const saveResult = await API.graphql(graphqlOperation(saveWarrantyStates, { States }));
  };

  const selectAll = () => {
    _.map(statesKeyed, (state, ShortName) => {
      const { checked, rendered, setValue } = state;
      setValue(true);
    });
  };
  const selectNone = () => {
    _.map(statesKeyed, (state, ShortName) => {
      const { checked, rendered, setValue } = state;
      setValue(false);
    });
  };
  

  if (warrantyStates.loading) {
    return <div>
        <h3>States Under Warranty</h3>
        <i>Loading.......</i>
      </div>;
  }

  return (
    <div>
      <h3>States Under Warranty</h3>
      <button onClick={selectAll} className="button" >Select All</button>
      <button onClick={selectNone} className="button" >Select None</button>
      <form onSubmit={submit}>
        {renderedStates}
        <input type="submit" value="Save States" />
      </form>
    </div>
  );
};

export default AdminWarrantyStates;
