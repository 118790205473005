// import PropTypes from "prop-types"
import _ from 'lodash';
import React, { useState, useEffect, Fragment } from 'react';
import { graphqlOperation, API }  from 'aws-amplify';
import { Link } from "gatsby"
import { getOrder } from '../../graphql/cart';
import Loading from '../Loading';
import { formatCurrency } from '../../utils/cart';

const AdminOrder = ({Id}) => {
    const [order, setOrder] = useState({loading: true, data: {}});
    useEffect(() => {
        const f = async () => {
            const result = await API.graphql(graphqlOperation(getOrder, {Id}));
            setOrder({loading: false, data: result.data.GetOrder});
        };
        f();
    }, []);

    const formatDate = (timestamp) => new Date(timestamp * 1000).toLocaleString();

    const buildProductTable = (product) => {
        return (
            <tr key={product.Id}>
                <td>
                    {product.Id}
                </td>
                <td>
                    {product.NamePlugtrays}
                </td>
                <td>
                    {product.Quantity}
                </td>
                <td>
                    {formatCurrency(product.PricePlugtrays)}
                </td>
            </tr>
        )
    };
    return (
        <Fragment>
        {order.loading === true ?
            <Loading />
            :
            <div className="grid-x">
                <div className="cell small-12">
                    <h4>Order ID {Id}</h4>
                    <p>Placed {formatDate(order.data.TimestampCreated)}</p>
                </div>
                <div className="cell small-12 medium-6">
                    <h3>Ship to:</h3>
                    <p>{order.data.Shipping.FirstName} {order.data.Shipping.LastName}</p>
                    <p>{order.data.Shipping.Address}</p>
                    <p>{order.data.Shipping.Address2 &&
                        order.data.Shipping.Address2}</p>
                    <p>{order.data.Shipping.City}, {order.data.Shipping.State} {order.data.Shipping.Zip}</p>
                    <p><strong>Phone: </strong>{order.data.Shipping.Phone}</p>
                    <p><strong>Email: </strong>{order.data.Shipping.Email}</p>
                </div>
                <div className="cell small-12 medium-6">
                    <h3>Bill to:</h3>
                    <p>{order.data.Billing.FirstName} {order.data.Billing.LastName}</p>
                    <p>{order.data.Billing.Address}</p>
                    <p>{order.data.Billing.Address2 &&
                        order.data.Billing.Address2}</p>
                    <p>{order.data.Billing.City}, {order.data.Billing.State} {order.data.Billing.Zip}</p>
                    <p><strong>Phone: </strong>{order.data.Billing.Phone}</p>
                    <p><strong>Email: </strong>{order.data.Billing.Email}</p>
                </div>
                <div className="cell small-12">
                    <h3>Order summary</h3>
                    <table>
                        <thead>
                            <tr>
                                <th>Product ID</th>
                                <th>Product name</th>
                                <th>Quantity</th>
                                <th>Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            {order.data.Products.map((product) => buildProductTable(product))}
                            <tr className="order--summary">
                                <td colSpan='4'><strong>Subtotal: </strong>{formatCurrency(order.data.Subtotal)}</td>
                            </tr>
                            <tr className="order--summary">
                                <td colSpan='4'>Tax: {formatCurrency(order.data.Tax)}</td>
                            </tr>
                            <tr className="order--summary">
                                <td colSpan='4'>Shipping: {formatCurrency(_.get(order, 'data.ShippingService.shippingService.Price'))}</td>
                            </tr>
                            <tr className="order--summary">
                                <td colSpan='4'>
                                    <strong>Total: {formatCurrency(order.data.Total)}</strong>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        }
        </Fragment>
    );
};

AdminOrder.propTypes = {
}

AdminOrder.defaultProps = {
}

export default AdminOrder;
