// import PropTypes from "prop-types"
import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { graphqlOperation, API }  from 'aws-amplify';
import { Link } from "gatsby"
import slugify from 'slugify';

const getAllTags = `
query {
  GetAllTags {
    TagsPlugtrays
  }
}
`;

const renderTagRow = (tagType, tag) => (
  <tr key={tag}>
      <td>{tag}</td>
  </tr>
);

const renderTagRowLinked = (tagType, tag) => (
  <tr key={tag}>
      <td><Link to={`/${slugify(_.toLower(tag))}`}>{tag}</Link></td>
  </tr>
);

const AdminTags = () => {
  const [tags, setTags] = useState({loading: true, data: {}});

  useEffect(() => {
    const f = async () => {
      const result = await API.graphql(graphqlOperation(getAllTags));
      setTags({loading: false, data: result.data.GetAllTags});
    };
    f();
  }, []);

  const renderedTagsPlugtrays = !tags.loading ? _.map(tags.data.TagsPlugtrays, _.partial(renderTagRowLinked, 'Plugtrays')) : undefined;

  return (
    <div>
      <h3>Tags</h3>
      <table>
          <tbody>
              {renderedTagsPlugtrays}
          </tbody>
      </table>
    </div>
  );
};

AdminTags.propTypes = {
}

AdminTags.defaultProps = {
}

export default AdminTags
