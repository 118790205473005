import _ from "lodash"
import React, { Fragment, useState, useEffect, useContext } from "react"
import { navigate } from "gatsby"
import { CartContext } from "../CartContext"
import store from "store"
import { getShippingOptions } from "../../graphql/cart"
import { checkout } from "../../graphql/cart"
import { graphqlOperation, API } from "aws-amplify"
import { formatCurrency } from "../../utils/cart"
import Button from "../Button"
import CartItem from "./CartItem"

const ZIP_STORAGE_KEY = "ZipV0"

const renderShippingOptionRow = (
  { DaysInTransit, Price, DeliveryTime },
  index
) => (
    <tr key={index}>
      <td>
        {DaysInTransit} business days (by {DeliveryTime || "End of Day"})
    </td>
      <td>${Price}</td>
    </tr>
  )

const Cart = () => {
  const [checkoutData, setCheckoutData] = useState({})
  const cartContext = useContext(CartContext)
  const cart = _.get(cartContext, "Cart")

  // console.log({cart, checkoutData});

  const { PlugtrayCount } = cart
  const { Tax, Total, Subtotal } = checkoutData

  useEffect(() => {
    const f = async () => {
      const result = await API.graphql(
        graphqlOperation(checkout, { Review: true })
      )
      const cd = _.get(result, "data.Checkout") || {}
      cd.ProductsById = _.keyBy(cd.Cart.Products, ({ Id }) => Id)
      setCheckoutData(cd)
    }
    f()
  }, [PlugtrayCount])

  const [zip, setZip] = useState(store.get(ZIP_STORAGE_KEY) || "")
  const [shippingOptions, setShippingOptions] = useState({
    loading: true,
    data: [],
  })

  useEffect(() => {
    const f = async () => {
      // TODO: better validation than this!
      if (_.size(zip) < 5 || !PlugtrayCount) {
        return
      }
      const AddressInput = {
        Zip: zip,
      }
      const shipping = await API.graphql(
        graphqlOperation(getShippingOptions, {
          Address: AddressInput,
          PlugtrayCount,
        })
      )
      setShippingOptions({
        loading: false,
        data: shipping.data.GetShippingOptions,
      })
      store.set(ZIP_STORAGE_KEY, zip)
    }
    f()
  }, [zip, PlugtrayCount])

  const beginCheckout = async () => {
    navigate("/app/checkout/shipping-address")
  }

  const renderedProducts = _.map(
    _.get(cart, "ProductsHydrated"),
    _.partial(CartItem, checkoutData.ProductsById)
  )
  const renderedShippingOptions = _.map(
    shippingOptions.data,
    renderShippingOptionRow
  )

  const productQuantitiesValidated = _.reduce(_.map(checkoutData.ProductsById, (Id) => Id.QuantityValidated));


  return (
    <Fragment>
      <h3>
        Shopping Cart <small>({cart.PlugtrayCount} items)</small>
      </h3>
      <div className="grid-x grid-margin-x">
        <div className="cell small-12 medium-8">
          <div className="grid-x grid-margin-x show-for-medium product-table--header__faux">
            <div className="cell medium-6">Item details</div>
            <div className="cell small-4 medium-1 currency-header">Price</div>
            <div className="cell small-4 medium-3 table-header__center">
              Quantity
            </div>
            <div className="cell small-4 medium-2 currency-header">Total</div>
          </div>
          {renderedProducts}
          <div className="grid-x grid-margin-x grid-padding-y grid-margin-y">
            <div className="cell small-12 step-bar">
              <div className="step-bar--subtotal">
                <p>Subtotal</p>
                <p className="currency">{formatCurrency(Subtotal)}</p>
              </div>
              <Button
                style="primary expanded"
                onClickHandler={beginCheckout}
                isDisabled={cart.PlugtrayCount === 0 || productQuantitiesValidated === 0}
                text="Check out"
              />
            </div>
          </div>
        </div>
        <div className="cell small-12 medium-4 order-summary--container__sticky">
          <div className="grid-x grid-padding-x grid-padding-y order-summary--container">
            <div className="cell small-12">
              <h4 className="header__small-caps">Order summary</h4>
              <p className="order-summary--item">
                Subtotal:{" "}
                <span className="currency">{formatCurrency(Subtotal)}</span>
              </p>
              <p className="order-summary--item">
                Estimated tax:{" "}
                <span className="currency">{formatCurrency(Tax)}</span>
              </p>
              <p className="order-summary--item">
                Estimated shipping:
                <span className="currency">--</span>
              </p>

              <p className="order-summary--item">
                <strong>
                  Estimated total:{" "}
                  <span className="currency">{formatCurrency(Total)}</span>
                </strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

Cart.propTypes = {}

Cart.defaultProps = {}

export default Cart
