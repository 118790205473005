// import PropTypes from "prop-types"
import _ from 'lodash';
import React, { useState, useEffect, Fragment } from 'react';
import { graphqlOperation, API }  from 'aws-amplify';
import Loading from '../Loading';
import Button from '../Button';
import config from '../../../config';
import CheckboxInput from '../CheckboxInput';

const ENV_CONFIG = config.get();

const getProduct = `
query ($productId: String!) {
  GetAcornProduct(Id: $productId) {
    Id
    CommonName
    BotanicalName
    NamePlugtrays
    DescriptionPlugtrays
    InStockPlugtrays
    PricePlugtrays
    MVGPath
    Height
    Hardiness
    FlowerColor
    Characteristics
    Uses
    TagsPlugtrays
    Images
    AdminImages
    DisplayPlugtrays
    Slug
  }
}
`;

const putProduct = `
mutation SaveProduct(
  $Id:ID!, 
  $CommonName: String
  $BotanicalName: String
  $NamePlugtrays: String
  $DescriptionPlugtrays: String
  $InStockPlugtrays: Int!
  $PricePlugtrays: Float!
  $MVGPath: String
  $Height: String
  $Hardiness: String
  $FlowerColor: String
  $Characteristics: String
  $Uses: String,
  $AdminImages: [String],
  $DisplayPlugtrays: Boolean ) {
    SaveProduct(product: {
      Id: $Id
      CommonName: $CommonName
      BotanicalName: $BotanicalName
      NamePlugtrays: $NamePlugtrays
      DescriptionPlugtrays: $DescriptionPlugtrays
      InStockPlugtrays: $InStockPlugtrays
      PricePlugtrays: $PricePlugtrays
      MVGPath: $MVGPath
      Height: $Height
      Hardiness: $Hardiness
      FlowerColor: $FlowerColor
      Characteristics: $Characteristics
      Uses: $Uses,
      AdminImages: $AdminImages,
      DisplayPlugtrays: $DisplayPlugtrays
    }) {
      Id
      CommonName
      BotanicalName
      NamePlugtrays
      DescriptionPlugtrays
      InStockPlugtrays
      PricePlugtrays
      MVGPath
      Height
      Hardiness
      FlowerColor
      Characteristics
      Uses
      TagsPlugtrays
      AdminImages
      DisplayPlugtrays
      Slug
    }
  }
`;


const READONLY_INPUTS=[
  'Id',
  'TagsPlugtrays',
  'MVGPath',
  'Slug',
];

const OMITTED_INPUTS=[
  'Images',
  'AdminImages',
  'DisplayPlugtrays',
];

const AdminProductDetail = ({ productId }) => {
  const [ product, setProduct ] = useState({loading: true, data: {}});
  const [saveState, setSaveState] = useState({saving: false, message: undefined});
  const [ adminImages, setAdminImages ] = useState([]);

  const [displayPlugtrays, displayPlugtraysRendered, setDisplayPlugtrays] = CheckboxInput({ labelText: 'Show on Plugtrays ?' });

  const fieldChanged = (key, event) => {
    const newData = {...product.data};
    _.set(newData, key, event.target.value);
    setProduct({loading: false, data: newData});
  };

  useEffect(() => {
    const f = async () => {
      const result = await API.graphql(graphqlOperation(getProduct, { productId }));
      const data = result.data.GetAcornProduct;
      // console.log({data}, 'got data!');
      setProduct({loading: false, data });
      setDisplayPlugtrays(data.DisplayPlugtrays);
      const adminImages = !_.isEmpty(data.AdminImages) ? data.AdminImages : data.Images;
      setAdminImages(adminImages);
    };
    f();
  }, []);

  const inputs = _.map(_.omit(product.data, OMITTED_INPUTS), (value, key) => (
    <label key={key}>
      {key}
      <input type="text" name={key} value={value} readOnly={_.includes(READONLY_INPUTS, key)} onChange={_.partial(fieldChanged, key)}/>
    </label>
  ));

  const handleSubmit = async () => {
    const intKeys = ['InStockPlugtrays', 'PricePlugtrays'];
    const AdminImages = _.size(adminImages) === _.size(product.data.Images) ? [] : adminImages;
    const productToSubmit = {...product.data, AdminImages};
    _.map(intKeys, (key) => _.set(productToSubmit, key, Number(_.get(product, ['data', key]))));
    try{
      setSaveState({saving: true});
      const result = await API.graphql(graphqlOperation(putProduct, {...productToSubmit, DisplayPlugtrays: displayPlugtrays}));
      console.log({result}, 'got submit result !!');
      setSaveState({saving: false, message: 'Successfully saved product'});
      setTimeout(() => setSaveState({saving: false}), 5000);
    } catch (err) {
      console.error({err});
      setSaveState({saving: false, message: `Error: ${err.toString()}`});
    }
  };



  const renderImageRowWithCheckbox = (image, index) => {
    const toggleSelected = () => {
      const isSelected = _.includes(adminImages, image);
      if (!isSelected) {
        setAdminImages([...adminImages, image]);
      } else {
        const newImages =  _.filter(adminImages, (i) => i !== image);
        setAdminImages(newImages);
      }
    };
    return <tr key={index}>
      <td>
        <input type='checkbox' checked={_.includes(adminImages, image)} onChange={toggleSelected}/>
      </td>
      <td>
        <img alt={`Image: ${image}`} src={`${ENV_CONFIG.mvgSiteUrl}${image}`}/>
      </td>
    </tr>;
  };


  const renderImageRow = (pathPrefix, Id, index) => (
    <tr key={index}>
      <td><img alt={`Image #${index + 1} Small`} src={`${pathPrefix}${Id}/${index}-small.jpg`}/></td>
      <td><img alt={`Image #${index + 1} Medium`} src={`${pathPrefix}${Id}/${index}-medium.jpg`}/></td>
      <td><img alt={`Image #${index + 1} Large`} src={`${pathPrefix}${Id}/${index}-large.jpg`}/></td>
    </tr>
  );

  const renderedImageRows = _.map(_.range(_.size(product.data.Images)), _.partial(renderImageRow, ENV_CONFIG.productImageRawCDNPath, productId));

  
  const renderedImageRowsAdminCDN = _.map(_.range(_.size(adminImages)), _.partial(renderImageRow, ENV_CONFIG.productImageCDNPath, productId));

  const renderedImageRowsWithCheckboxes = _.map(product.data.Images, renderImageRowWithCheckbox);
  return (
    <Fragment>
      {product.loading === true ?
        <Loading />
      :
        <div className='grid-x'>
          <div className="cell small-12">
            <h3>{product.data.NamePlugtrays}</h3>
            {displayPlugtraysRendered}
            {inputs}
            <Button
             text={product.loading || saveState.saving ? 'Working....' : 'Save Product'} 
             style={product.loading || saveState.saving ? 'loading' : undefined}
             isDisabled={product.loading || saveState.saving} 
             onClickHandler={handleSubmit} 
            />
            <h4>{saveState.message}</h4>

            <h3>Product Detail Images</h3>
            <table>
              <thead>
                <tr>
                  <th>Include on product detail page?</th>
                  <th>Raw Image</th>
                </tr>
              </thead>
              <tbody>
                {renderedImageRowsWithCheckboxes}
              </tbody>
            </table>
            <h3>CDN Images</h3>
            <table>
              <thead>
                <tr>
                  <th>Small</th>
                  <th>Medium</th>
                  <th>Large</th>
                </tr>
              </thead>
              <tbody>
                {renderedImageRows}
              </tbody>
            </table>
          </div>
          <h3>Admin CDN Images</h3>
            <table>
              <thead>
                <tr>
                  <th>Small</th>
                  <th>Medium</th>
                  <th>Large</th>
                </tr>
              </thead>
              <tbody>
                {renderedImageRowsAdminCDN}
              </tbody>
            </table>
        </div>
      }
    </Fragment>
  );
};

AdminProductDetail.propTypes = {
}

AdminProductDetail.defaultProps = {
}

export default AdminProductDetail
