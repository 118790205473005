import React, {useState, useEffect} from 'react';
import { navigate } from "gatsby";
import { logout } from '../utils/auth'
import Auth from '@aws-amplify/auth'

const Logout = () => {

    useEffect(() => {
        const f = async () => {
            try {
                let signoutResult = await Auth.signOut({global: true});
                logout();
                // console.log({signoutResult}, 'Successfully signed out');
                navigate('/');
            } catch (err) {
                console.error({signoutError: err});
            }
            navigate('/');
        };
        f();
    }, []);

    return <div></div>;
}

export default Logout;